import { MarketplaceOrderTypes } from "./types";

export const MarketplaceOrderStore = (
  state = {
    submitting: false,
    order: {},
    tokenReady: false,
    paymentSettled: false
  },
  action
) => {

  switch(action.type) {
    case MarketplaceOrderTypes.SUBMITTING:
      return {
        ...state,
        submitting: true,
      }
    case MarketplaceOrderTypes.RECEIVE_CUSTOMER:
      return {
        ...state,
        submitting: false,
        stuff: action.data,
      }
    case MarketplaceOrderTypes.RECEIVE_ORDER:
      return {
        ...state,
        submitting: false,
        order_id: action.data.id,
        user: action.data.user,
        company: action.data.company,
      }
    case MarketplaceOrderTypes.TOKEN_RECEIVED:
      return {
        ...state,
        tokenReady: true,
        submitting: true
      }
    case MarketplaceOrderTypes.PAYMENT_RECEIVED:
      return {
        ...state,
        payment: action.data,
        paymentReceived: action.data.state === "pending"
      }
    case MarketplaceOrderTypes.PAYMENT_UPDATED:
      return {
        ...state,
        payment: action.data,
        paymentSettled: action.data.state === "settled",
        submitting: false
      }
    default:
      return state;
  }
};
