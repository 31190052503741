import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { commonFetch } from "@utils";

const campaignBannerSizesAdapter = createEntityAdapter();
const initialState = campaignBannerSizesAdapter.getInitialState(); // {ids: [], entities: []} structure

export const { selectById: selectBannerSizesByCampaignId } = campaignBannerSizesAdapter.getSelectors((state) => state.CampaignBannerSizesStore);

export const fetchCampaignBannerSizes = createAsyncThunk(
  "fetchCampaignBannerSizes",
  async (args, thunkAPI) => {
    const url = `/api/v2/campaigns/${args.id}/banner_sizes`
    let data = await commonFetch(url, args, thunkAPI);
    thunkAPI.dispatch(initializeCampaignBannerSizes({id: args.id, data: data}));
  }
);

const campaignBannerSizesSlice = createSlice({
  name: 'campaignBannerSizes',
  initialState,
  reducers: {
    initializeCampaignBannerSizes(state, action) {
      campaignBannerSizesAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignBannerSizes.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchCampaignBannerSizes.fulfilled, (state, action) => {
        state.isFetching = false;
      })
  }
});

export const { initializeCampaignBannerSizes } = campaignBannerSizesSlice.actions;
const { reducer } = campaignBannerSizesSlice;
export default reducer;
