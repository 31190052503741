import React, { useState } from "react";
import CommonInput from '../common_input';

const TextInput = (props) => (
  <CommonInput
    type='text'
    {...props }/>
);

export default TextInput;
