import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, EmailInput, PasswordInput } from "../shared/form";

const LoginForm = (props) => {
  const { register, handleSubmit, setError, formState: { errors } } = useForm({criteriaMode: "all"});

  const onSubmit = data => {
    document.getElementById("loginForm").submit();
  }

  useEffect(() => {
    if(props.alert !== ""){
      setError("user.password", { type: "string", message: props.alert });
    }
  }, [1]);

  return (
    <form id="loginForm" action="/login" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <div className="row">
        <FormGroup label="Email" required={true} name="user[email]" errorMessage={errors.user && errors.user.email}>
          <EmailInput
            name="user[email]"
            placeholder="Email"
            register={register}
            validation={ { required: 'Please provide your email address' } }
            initialValue=""
          />
        </FormGroup>
        <FormGroup label="Password" required={true} name="user[password]" errorMessage={errors.user && errors.user.password}>
          <PasswordInput
            name="user[password]"
            placeholder="Password"
            register={register}
            validation={ { required: 'Please provide your password' } }
            initialValue=""
          />
        </FormGroup>
      </div>
      <div className="actions p-0">
        <a href="/users/password/new" className="float-end mt-0">Forgot your password?</a>
        <input type="submit" value="Login" className="btn btn-success" />
      </div>
    </form>
  )
}

export default LoginForm;
