import React, { useState, useDebugValue } from "react";
import { Provider, connect, useDispatch } from "react-redux";
import { store } from "../store";
import moment from 'moment';
import numeral from 'numeral';
import * as bootstrap from 'bootstrap';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

import { createRoot } from 'react-dom/client';

export const postHeaders = () => ({
  ...headers,
  "X-CSRF-Token": Rails.csrfToken(),
});

export const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
};

export const multiUploadHeaders = () => ({
  // "Content-Type": "multipart/form-data",
  "X-CSRF-Token": Rails.csrfToken(),
});

export const postFormHeaders = () => ({
  "X-CSRF-Token": Rails.csrfToken(),
});

export const initializeAllComponents = (Component, selector) => {
  document.querySelectorAll(selector).forEach(function(node) {
    initializeNode(Component, node);
  });
};

export const initializeComponent = (Component, selector) => {
  let node = document.querySelector(selector);
  initializeNode(Component, node);
};

export const initializeNode = (Component, node) => {
  if (node) {
    const options = {};
    const ds = node.dataset;
    for (let key in ds) {
      let val = ds[key];
      try {
        options[key] = JSON.parse(val);
      } catch {
        options[key] = val;
      }
    }

    const root = createRoot(node);
    root.render(
      <Provider store={store}>
        <Component {...options} />
      </Provider>
    );
  }
};

export const checkResponse = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const redirectFromResponse = (response) => {
  window.location = response.url;
}

export const bytesToHumanReadable = (bytes) => {
  const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const getBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const precision = i >= 2 ? 2 : 0;
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(precision) + " " + suffixes[i];
  };

  return getBytes(bytes);
};

export const parseJSON = (response) => {
  if (response.status === 204) return true;

  return response.json();
};

export const toNumeral = (num) => numeral(num).format("0,000.[00]");

export const toIcelandicDate = (dateStr, inputDate = moment(dateStr)) =>
  inputDate.isValid() ? inputDate.locale("is").format("L") : "";

export const reduceObjectList = (list, attribute) =>
  list.reduce(
    (totalAttribute, object) => totalAttribute + object[attribute],
    0
  );

export const averageObjectList = (list, attribute) =>
  reduceObjectList(list, attribute) / Math.max(list.length, 1);

export const percentageOfTotal = (value, total) => ((value / total) * 100).toFixed(2);

export const compareOrderedArrays = (array1, array2) => {
  if (array1.length !== array2.length) return false;

  for (var i = 0; i < array1.length; ++i) {
    if (array1[i] !== array2[i]) return false;
  }

  return true;
};

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const isEmptyObject = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export const isObject = obj => obj instanceof Object;

// group an array of objects by key
export const groupBy = (arrayToGroup, key) =>
  arrayToGroup.reduce((r, a) => {
    r[a[key]] = [...(r[a[key]] || []), a];
    return r;
  }, {});

export const isUndefined = (obj) => obj === undefined;

export const uniq = (arr) => [...new Set(arr)];

export const handleNetworkError = (error) => {
  if (window.Raven !== undefined) {
    Raven.captureException(error)
  } else {
    throw (error);
  }
}

export const updateUserPreference = (user_id, data) => {
  Rails.ajax({
    url: `/users/${user_id}/preference`,
    type: 'PUT',
    data: data
  })
}

export const capitalize = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
};

export const useToggle = (initialValue = false) => {
  const [value, setValue] = React.useState(initialValue);
  const toggle = React.useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
}

export const formattedDaysFromNow = (number_of_days) => {
  const date = new Date();
  date.setDate(date.getDate() - number_of_days);
  return moment(date).format("YYYY-MM-DD")
}

export const useStateWithDebugLabel = (initialValue, label) => {
  const [value, setValue] = useState(initialValue);
  if(process.env.NODE_ENV === 'development') {
    useDebugValue(`${label}: ${value}`);
  }
  return [value, setValue];
};

/*
 * Bosed on: https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
 */
export const formatBytes = (bytes, decimals = 0) => {
  if(bytes === undefined || bytes === null) return 'N/A';
  if(bytes == 0) return '0 Bytes';

  var k = 1024,
  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

/*
 * Based on https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 */
export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.host.includes('.') && ['http:', 'https:'].includes(url.protocol) && string.startsWith(url.protocol + "//");
}

export const isValidScriptTag = (string) => {
  let r = /^<(script|iframe)[\s\S]*?>[\s\S]*?<\/(script|iframe)>/gi;
  let match = false
  try {
    match = string.match(r)[0]
  } catch (_) {
    return false;  
  }
  return match
}

export const isCommentedString = (string) => {
  return string.match(/^(\/\/|<!--).*$/)
}

export const postWithJsonData = (url, data) => {
  return fetch(url, {
    headers: postHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
}

export const getJsonData = (url, dispatch, pre_dispatch, post_dispatch) => {
  if (pre_dispatch) {
    dispatch(pre_dispatch);
  }
  return fetch(url, {
    headers,
  })
    .then(parseJSON)
    .then((response) => {
      if (post_dispatch) {
        dispatch(post_dispatch(response))
      } else {
        return response;
      }
    });
}

export const commonFetch = async (url, args, thunkAPI) => {
  try {
    const response = await fetch(url, { method: "GET", headers: headers })
      .then(checkResponse)
      .then((response) => response.json());

    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

// Convert HH:mm to a date object
export const dateFromTime = (timeString) => {
  const s = timeString.split(":")
  const d = new Date();
  d.setHours(s[0], s[1])
  return d;
}

export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const futureDate = new Date(time);
  return currentDate.getTime() < futureDate.getTime();
};

export const closeModal = () => {
  const myModalEl = document.getElementById('modal-container')
  const modal = bootstrap.Modal.getInstance(myModalEl)
  modal.hide();
  setTimeout(() => {
    modal.dispose();
  }, 500);
};

export const copyToClipboard = (val) => {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = val;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

// Find height/width from script
export const findValueFromScript = (searchStr, script) => {
  let matches = '';
  let r = /\d+/;
  let tmpList = script.split(' ')
  for (let i in tmpList){
    if (tmpList[i].includes(searchStr)){
      matches = tmpList[i].match(r)
    }
  }
  return (matches && parseInt(matches[0]));
}

export const tableToPDF = (selector, filename_prefix) => {
  const pdf = new jsPDF();
  autoTable(pdf, { 
    html: selector,
    headStyles: {
      fillColor: "#b73966"
    },
    footStyles: {
      lineWidth: {top: 0.5},
      lineColor: "#000066",
      textColor: "#3f3f3f",
      fillColor: "#fff",
    }
  })

  const timestamp = new Date(Date.now()).toISOString().slice(0, 19);
  pdf.save(`${filename_prefix}-${timestamp}.pdf`)
}

