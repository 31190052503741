import React from "react";
import PropTypes from "prop-types";
import InputGroup from "../input_group";
import { invalidField } from "../styles";

const SelectInput = ({
  value = "",
  options,
  name = "",
  onChange = () => {},
  register,
  inputGroupClassName,
  postfix,
  errorMessage,
  validation,
}) => (
  <InputGroup className={inputGroupClassName} postfix={postfix}>
    <select
      style={errorMessage && invalidField}
      defaultValue={value}
      className="form-control"
      {...register(name, validation)}
      onChange={event => onChange(event)}
    >
      {options.map(option => (
        <option key={`${option.value}-${option.name}`} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
    <span className="text-danger">{errorMessage && errorMessage.message}</span>
  </InputGroup>
)

SelectInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func,
};

export default SelectInput;
