import { createTypes } from "redux-action-creator";

export const AccountVendorGroupTypes = createTypes(
  [
    "FETCH_VENDORGROUPS",
    "FETCH_VENDORGROUPS_COMPLETE",
    "FETCH_VENDORGROUPS_ERROR",
    "CREATE_VENDORGROUP",
    "CREATE_VENDORGROUP_COMPLETE",
    "DELETE_VENDORGROUP",
    "DELETE_VENDORGROUP_COMPLETE",
    "CREATE_VENDORGROUP_ITEM",
    "CREATE_VENDORGROUP_ITEM_COMPLETE",
    "DELETE_VENDORGROUP_ITEM",
    "DELETE_VENDORGROUP_ITEM_COMPLETE",
  ],
  "AccountVendorGroupTypes"
);
