import { initializeComponent } from '@utils';
import LoginForm from './login_form';
import AcceptInvitationForm from './accept_invitation_form';
import PasswordResetEmailForm from './password_reset_email_form';
import PasswordResetForm from './password_reset_form';

document.addEventListener("DOMContentLoaded", function() {
  initializeComponent(LoginForm, "#login_form");
  initializeComponent(AcceptInvitationForm, "#accept_invitation_form");
  initializeComponent(PasswordResetEmailForm, "#password_reset_email_form");
  initializeComponent(PasswordResetForm, "#password_reset_form");
});
