import { CampaignTypes } from "./types";

export const CampaignStore = (
  state = {
    isFetching: false,
    data: [],
    deletedId: null,
    deletionFailed: false,
    refresh: false,
  },
  action
) => {
  switch (action.type) {
    case CampaignTypes.FETCH:
      return {
        ...state,
        isFetching: action.data.loading,
      };
    case CampaignTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case CampaignTypes.DELETE_SUCCESS:
      return {
        ...state,
        deletedId: action.data.id,
        hasRestored: false,
      };
    case CampaignTypes.DELETE_FAILED:
      return {
        ...state,
        deletionFailed: true,
      }
    case CampaignTypes.RESTORE_SUCCESS:
      return {
        ...state,
        hasRestored: true,
        deletedId: null,
      };
    case CampaignTypes.REFRESH:
      return {
        ...state,
        isFetching: false,
        refresh: true,
      };
    default:
      return state;
  }
};
