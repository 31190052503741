import { createAsyncThunk, createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { sub } from "date-fns";

const campaignFiltersAdapter = createEntityAdapter();
const initialState = campaignFiltersAdapter.getInitialState(); // {ids: [], entities: []} structure

export const { selectById: selectCampaignFiltersById } = campaignFiltersAdapter.getSelectors((state) => state.CampaignFiltersStore);

const { selectAll: selectAllCampaignFilters, selectById: selectCampaignFilter } = campaignFiltersAdapter.getSelectors((state) => state);
const current = (state, action) => selectCampaignFilter(state, action.payload.id);

const writeToLocalStorage = (state, action) => {
  let currentFilters = current(state, action);
  let campaignFilters = JSON.parse(window.localStorage.getItem("campaignFilters")) || {};

  currentFilters.updatedAt = new Date();
  campaignFilters[action.payload.id] = currentFilters;
  window.localStorage.setItem("campaignFilters", JSON.stringify(campaignFilters));
}

const readFromLocalStorage = (state, action) => {
  let campaignFilters = JSON.parse(window.localStorage.getItem("campaignFilters")) || {};
  return campaignFilters[action.payload.id];
}

const updateLocalStorage = (state, action) => {
  let currentFilters = current(state, action);
  if (currentFilters.showPreview && !currentFilters.hideUnpublished && currentFilters.activeSizes.length === currentFilters.totalSizes) {
    // Default settings, no need to store
    deleteFromLocalStorage(state, action);
  } else {
    writeToLocalStorage(state, action);
  }
}

const deleteExpiredFilters = (state, action) => {
  let campaignFilters = JSON.parse(window.localStorage.getItem("campaignFilters")) || {};
  let values = Object.values(campaignFilters);
  const expiryDate = sub(new Date(), { months: 3 })
  let expired = values.filter((el) => new Date(el.updatedAt) <= expiryDate);
  expired.forEach((val) => {
    delete campaignFilters[val.id];
  });
  window.localStorage.setItem("campaignFilters", JSON.stringify(campaignFilters));
}

export const clearLocalStorage = createAsyncThunk(
  "clearLocalStorage",
  async (args, thunkAPI) => {
    await new Promise((resolve) => { setTimeout(resolve, 1000); });
    window.localStorage.removeItem("campaignFilters");
  }
);

const deleteFromLocalStorage = (state, action) => {
  let campaignFilters = JSON.parse(window.localStorage.getItem("campaignFilters")) || {};
  delete campaignFilters[action.payload.id];
  window.localStorage.setItem("campaignFilters", JSON.stringify(campaignFilters));
}

const checkDefaultSettings = (state, action) => {
  let curr = current(state, action);
  console.log("current", curr);
  console.log("current.activeSizes", curr.activeSizes);
  let isDefault = curr.activeSizes.length === curr.totalSizes && curr.showPreview && !curr.hideUnpublished
  campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: { defaultSetting: isDefault }});
}

const campaignFiltersSlice = createSlice({
  name: 'campaignFilters',
  initialState,
  reducers: {
    initializeActiveSizes(state, action) {
      let localVersion = readFromLocalStorage(state, action);
      if (localVersion) {
        campaignFiltersAdapter.upsertOne(state, localVersion);
      } else {
        campaignFiltersAdapter.upsertOne(state, action.payload);
      }
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: {loaded: true}});
    },
    activateSizeFilter(state, action) {
      let activeSizes = current(state, action).activeSizes.concat({width: action.payload.width, height: action.payload.height});
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: { activeSizes: activeSizes }});
      checkDefaultSettings(state, action);
      updateLocalStorage(state, action);
    },
    deactivateSizeFilter(state, action) {
      let activeSizes = current(state, action).activeSizes.filter((el) => (el.width !== action.payload.width) || (el.height !== action.payload.height));
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: {activeSizes: activeSizes}});
      checkDefaultSettings(state, action);
      writeToLocalStorage(state, action);
    },
    publishedFilter(state,action) {
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: { hideUnpublished: action.payload.hideUnpublished }});
      checkDefaultSettings(state, action);
      updateLocalStorage(state, action);
    },
    previewFilter(state, action) {
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: { showPreview: action.payload.showPreview }});
      checkDefaultSettings(state, action);
      updateLocalStorage(state, action);
    },
    resetFilters(state, action) {
      campaignFiltersAdapter.updateOne(state, {id: action.payload.id, changes: { showPreview: true, hideUnpublished: false, activeSizes: action.payload.activeSizes, defaultSetting: true }});
      deleteFromLocalStorage(state, action);
    },
    purgeExpiredFilters(state, action) {
      deleteExpiredFilters(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearLocalStorage.pending, (state, action) => {
        campaignFiltersAdapter.removeAll(state);
        state.isClearing = true;
      })
      .addCase(clearLocalStorage.fulfilled, (state, action) => {
        state.isClearing = false;
      })
  }
});

export const { activateSizeFilter, deactivateSizeFilter, filterVisibleSizes, initializeActiveSizes, publishedFilter, previewFilter, purgeExpiredFilters, resetFilters } = campaignFiltersSlice.actions;
const { reducer } = campaignFiltersSlice;

export default reducer;
