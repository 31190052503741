import { createTypes } from 'redux-action-creator';

export const MarketplaceCampaignTypes = createTypes([
  'ADDING_BANNER',
  'CREATE',
  'DELETE_BANNER',
  'FETCHING',
  'RECEIVE_CAMPAIGNS',
  'RECEIVE_EXISTING_CAMPAIGN',
  'RECEIVE_NEW_CAMPAIGN',
  'RECEIVE_BANNER',
  'SET_ORDER_DATE_RANGE',
  'SET_SECTIONS',
  'UPDATE_BANNER',
  'UPDATE_SECTION',
  'DEBUG',
], 'MarketplaceCampaignTypes');
