import { createTypes } from 'redux-action-creator';

export const BannerScheduleTypes = createTypes([
  'FETCH',
  'RECEIVE',
  'REQUEST',
  'DELETE_SUCCESS',
  'DELETE_FAILED',
  'RECEIVE_RECURRING',
  'DELETE_SUCCESS_RECURRING',
  'DELETE_FAILED_RECURRING',
], 'BannerScheduleTypes');