import { BundleTypes } from "./types";

export const BannerBundleStore = (
  state = {
    isFetching: false,
    isSent: false,
    data: [],
    response: '',
    sent_bundles: []
  },
  action
) => {
  switch (action.type) {
    case BundleTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case BundleTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case BundleTypes.RECEIVE_SENT:
      return {
        ...state,
        isFetching: false,
        sent_bundles: action.data,
      };
    case BundleTypes.CREATED:
      return {
        ...state,
        saved: true
      };
    case BundleTypes.SEND_SUCCESS:
      return {
        ...state,
        response: action.data,
        isSent: true
      };
    case BundleTypes.CLEAR_MAIL_DATA:
      return {
        ...state,
        response: '',
        isSent: false
      };
    default:
      return state;
  }
};
