import { createTypes } from "redux-action-creator";

export const SuperadminTypes = createTypes(
  [
    "CLEAR_ACCOUNT",
    "UPDATING_ACCOUNT",
    "UPDATING_ACCOUNT_COMPLETE",
    "FETCH_ACCOUNT",
    "RECEIVE_ACCOUNT",
    "FETCH_ACCOUNTS",
    "RECEIVE_ACCOUNTS",
    "FETCH_HEARTBEAT_ACCOUNTS",
    "RECEIVE_HEARTBEAT_ACCOUNTS",
    "FETCH_ACCOUNTS_DATA",
    "RECEIVE_ACCOUNTS_DATA",
    "BILLING_COMPLETE",
  ],
  "SuperadminTypes"
);
