import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonFetch, headers, checkResponse } from "@utils";

export const fetchWebsiteAnalytics = createAsyncThunk(
  "fetchWebsiteAnalytics",
  async (args, thunkAPI) => {
    const url = `/api/v2/reports/ads/?from_date=${args.start}&to_date=${args.end}&filter_id=${args.id}&filter_type=website`
    return commonFetch(url, args, thunkAPI);
  }
);

const initialState = {
  data: [],
  isFetching: true,
};

const websiteAnalyticsSlice = createSlice({
  name: 'websiteAnalytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteAnalytics.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchWebsiteAnalytics.fulfilled, (state, action) => {
        state.isFetching = false;
        state.data = action.payload;
      })
  }
});

const { reducer } = websiteAnalyticsSlice;
export default reducer;
