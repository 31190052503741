import { FlashMessageTypes } from "./types";

export const FlashMessageStore = (
  state = {
    flashType: null,
    name: "",
    duration: 0,
    actionType: "",
  },
  action
) => {
  switch (action.type) {
    case FlashMessageTypes.SET:
      return {
        ...action.data,
      };
    case FlashMessageTypes.CLEAR:
      return {
        flashType: null,
        name: "",
        duration: 0,
        actionType: "",
      };
    default:
      return state;
  }
};