import { AccountPaymentTypes } from "./types";

export function AccountPaymentStore (
  state = {
    isUpdating: false,
    isRegisteringSubscription: false,
    cardData: {}
  },
  action
) {
  switch (action.type) {
    case AccountPaymentTypes.SUBMIT_CREDITCARD:
      return {
        ...state,
        isUpdating: true,
        customer: {
          first_name: action.data.first_name,
          last_name: action.data.last_name,
          billing_email: action.data.billing_email,
        }
      };
    case AccountPaymentTypes.RECEIVE_TEMP_TOKEN:
      return {
        ...state,
        isUpdating: false,
        token: action.data.token,
        card_verification_url: action.data.card_verification_url
      }
    case AccountPaymentTypes.RECEIVE_TOKEN_STATUS:
      return {
        ...state,
        tokenStatus: action.data.status,
      }
    case AccountPaymentTypes.REGISTER_SUBSCRIPTION:
      return {
        ...state,
        isUpdating: true,
      }
    case AccountPaymentTypes.REGISTER_SUBSCRIPTION_COMPLETE:
      return {
        ...state,
        isUpdating: false,
      }
    case AccountPaymentTypes.REGISTER_PAYMENT_ERROR:
      return {
        ...state,
        isUpdating: false,
        paymentError: true
      }
    case AccountPaymentTypes.CLEAR_PAYMENT_STATE:
      return {
      }
    default:
      return state;
  }
}
