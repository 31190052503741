import { AccountStatsTypes } from "./types";

export const AccountStatsStore = (
  state = {
    isFetching: false,
    isFetchingMaxImpressions: false,
    data: {},
    max_impressions: {}
  },
  action
) => {
  switch (action.type) {
    case AccountStatsTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case AccountStatsTypes.FETCH_MAX_IMPRESSIONS:
      return {
        ...state,
        isFetchingMaxImpressions: true,
      };
    case AccountStatsTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case AccountStatsTypes.RECEIVE_MAX_IMPRESSIONS:
      return {
        ...state,
        isFetchingMaxImpressions: false,
        data: { ...state.data, ...action.data }
      };
    default:
      return state;
  }
};
