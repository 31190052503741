import { createTypes } from 'redux-action-creator';

export const MarketplaceOrderTypes = createTypes([
  'PAYMENT_RECEIVED',
  'PAYMENT_UPDATED',
  'SUBMITTING',
  'RECEIVE_CUSTOMER',
  'RECEIVE_ORDER',
  'TOKEN_RECEIVED',
], 'MarketplaceOrderTypes');
