import { createTypes } from 'redux-action-creator';

export const CampaignTypes = createTypes([
  'FETCH',
  'RECEIVE',
  'DELETE_SUCCESS',
  'DELETE_FAILED',
  'RESTORE_SUCCESS',
  'REFRESH',
], 'CampaignTypes');
