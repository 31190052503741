import { CompanyTypes } from "./types";

export const CompanyStore = (
  state = {
    isFetching: false,
    saved: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case CompanyTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case CompanyTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case CompanyTypes.RECEIVE_COMPANY:
      return {
        ...state,
        isFetching: false,
        data: state.data.map(company =>  {
          if (company.id === action.data.id) {
            return {
              ...company,
              ...action.data 
            }
          }

          return company;
        })
      };
    case CompanyTypes.NEW:
      return {
        ...state,
        saved: false
      };
    case CompanyTypes.CREATED:
      return {
        ...state,
        saved: true
      };
    case CompanyTypes.UPDATED:
      return {
        ...state,
        saved: true
      };
    case CompanyTypes.UPDATE_BRANDS:
      return {
        ...state,
        brands: action.brands
      };
    default:
      return state;
  }
};
