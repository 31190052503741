import { createTypes } from 'redux-action-creator';

export const BundleTypes = createTypes([
  'CREATED',
  'FETCH',
  'RECEIVE',
  'RECEIVE_SENT',
  'SENDING',
  'SEND_SUCCESS',
  'SEND_ERROR',
  'CLEAR_MAIL_DATA',
], 'BundleTypes');
