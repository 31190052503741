import { MetaBannerTypes } from "./types";
import { receiveMetaBanner } from "./operations"

export const MetaBannerStore = (
  state = {
    isFetching: false,
    bannerId: null,
    data: [],
    deletedId: null,
    deletionFailed: false,
    refresh: false,
    banners: {},
  },
  action
) => {
  switch (action.type) {
    case MetaBannerTypes.CREATED:
      return {
        ...state,
        isFetching: false,
        bannerId: action.data.id,
        data: [...state.data, action.data]
      };
    case MetaBannerTypes.EDITING:
      return {
        ...state,
        bannerId: null
      };
    case MetaBannerTypes.FETCH:
      return {
        ...state,
        isFetching: true,
        refresh: false,
      };
    case MetaBannerTypes.DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((banner) => banner.id != action.data.id),
        deletedId: action.data.id,
        refresh: true,
      };
    case MetaBannerTypes.DELETE_FAILED:
      return {
        ...state,
        deletionFailed: true,
      }
    case MetaBannerTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        refresh: false,
      };
    case MetaBannerTypes.RECEIVE_META_BANNER:
      return {
        ...state,
        isFetching: false,
        banners: {
          ...state.banners,
          [action.data.id]: {
            isFetching: false,
            published: action.data.published === true,
          }
        },
        refresh: false,
        data: receiveMetaBanner(action, state)
      };
    case MetaBannerTypes.UPDATED:
      // const newArray = state.data;
      // const index = newArray.findIndex(meta_banner => meta_banner.id === action.data.id);
      // newArray.splice(index, 1, action.data)

      return {
        ...state,
        isFetching: false,
        bannerId: action.data.id,
        data: state.data.map(meta_banner => (meta_banner.id === action.data.id) ? action.data : meta_banner)
        // data: newArray
        // data: [...state.data, action.data]
      };
    default:
      return state;
  }
};
