import { createTypes } from 'redux-action-creator';

export const MetaBannerTypes = createTypes([
  'CREATED',
  'UPDATED',
  'DELETE_FAILED',
  'DELETE_SUCCESS',
  'EDITING',
  'FETCH',
  'RECEIVE',
  'RECEIVE_META_BANNER',
  'REFRESH'
], 'MetaBannerTypes');
