import { matchSorter } from "match-sorter";
import { isEmpty } from "@utils";

const searchFields = ["title", "width", "height", "updated_at", "view_size"];

export const formatData = (data, searchValue = "") =>
  matchSorter(data, searchValue, {
    keys: searchFields,
    threshold: matchSorter.rankings.CONTAINS,
  });

export const receiveBanner = (action, state) => {
  if(showContext(state)){
    return action.data;
  }
  return state.data.map(banner => banner.id === action.data.id ? action.data : banner);
}

export const filterData = (data, filters, searchValue) => {
  if (isEmpty(filters)) return formatData(data, searchValue);

  let filteredData = data;
  if (filters.hideUnpublished) {
    filteredData = filteredData.filter(el => el.published);
  }

  if (filters?.activeSizes) {
    filteredData = filteredData.filter((el) => filters.activeSizes.find((vs) => vs.width === el.width && vs.height === el.height));
  }

  return formatData(filteredData, searchValue);
};

const showContext = (state) => state.data.length === 0 || state.data.id !== undefined

