import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, EmailInput, PasswordInput } from "../shared/form";

const PasswordResetEmailForm = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm({criteriaMode: "all"});

  const onSubmit = data => {
    document.getElementById("passwordResetEmailForm").submit();
  }

  return (
    <form id="passwordResetEmailForm" action="/users/password" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <div className="row">
        <FormGroup label="Email" required={true} name="user[email]" errorMessage={errors.user && errors.user.email}>
          <EmailInput
            name="user[email]"
            placeholder="Email"
            register={register}
            validation={ { required: 'Please provide your email address' } }
            initialValue=""
          />
          </FormGroup>
        </div>
        <div className="actions">
          <a href="/login">Back to login</a>
          <input type="submit" value="Reset my password" className="btn btn-success col-xs-4 float-end" />
        </div>
      </form>
  )
}

export default PasswordResetEmailForm;
