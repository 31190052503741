import { VideoZoneTypes } from "./types";

export const VideoZoneStore = (
  state = {
    isFetching: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case VideoZoneTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case VideoZoneTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case VideoZoneTypes.REQUEST:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
