import { createTypes } from "redux-action-creator";

export const AccountPaymentTypes = createTypes(
  [
    "SUBMIT_CREDITCARD",
    "REQUEST_TEMP_TOKEN",
    "RECEIVE_TEMP_TOKEN",
    "ERROR_TEMP_TOKEN",
    "REQUEST_TOKEN_STATUS",
    "RECEIVE_TOKEN_STATUS",
    "ERROR_TOKEN_STATUS",
    "REGISTER_SUBSCRIPTION",
    "REGISTER_SUBSCRIPTION_COMPLETE",
    "REGISTER_PAYMENT_ERROR",
    "CLEAR_PAYMENT_STATE",
  ],
  "AccountPaymentTypes"
);
