import { parseJSON, postHeaders, checkResponse, multiUploadHeaders } from "@utils";

const deleteBanner = async ({id}) => {
  const response = await fetch(`/api/v2/banners/${id}`, {
    headers: postHeaders(),
    method: "DELETE",
  });
  return { id };
};

const updateBannerById = (id, data) => {
  return fetch(`/api/v2/banners/${id}`, {
    method: "PATCH",
    headers: multiUploadHeaders(),
    body: data,
  })
    .then(checkResponse)
    .then((response) => response.json());
};

const createMulti = async (campaignId, data) => {
  const response = await fetch(`/campaigns/${campaignId}/banner_uploads`, {
    method: "POST",
    headers: multiUploadHeaders(),
    body: data,
  });
  return await response.json();
};

const MultiUploaderService = {
  deleteBanner,
  updateBannerById,
  createMulti,
};

export default MultiUploaderService;
