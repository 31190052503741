import { matchSorter } from "match-sorter";

const searchFields = ["title", "width", "height", "updated_at", "view_size"];

export const formatData = (data, searchValue = "") =>
  matchSorter(data, searchValue, {
    keys: searchFields,
    threshold: matchSorter.rankings.CONTAINS,
  });

export const addSingle = (action, state) => {
  let tmpState = [...state.data]
  tmpState.push(action.data)
  return tmpState
}

export const updateSingle = (action, state) => {
  let tmpState = [...state.data]
  let upd_idx = tmpState.findIndex((obj => obj.id == action.data.id));
  tmpState[upd_idx] = action.data
  return tmpState
}
