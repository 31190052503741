import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, EmailInput, PasswordInput } from "../shared/form";

const PasswordResetForm = (props) => {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({criteriaMode: "all"});
  const { resetToken } = props;

  const onSubmit = data => {
    document.getElementById("passwordResetForm").submit();
  }

  const validatePasswordConfirmation = (value) => {
    const password = getValues(["user[password]"])[0];
    return (password === value) || "Passwords do not match";
  }

  return (
    <form id="passwordResetForm" action="/users/password" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <input type="hidden" name="_method" value="put" />
      <input type="hidden" name="user[reset_password_token]" value={resetToken} />
      <div className="row">
        <FormGroup label="Password" required={true} name="user[password]" errorMessage={errors.user && errors.user.password}>
          <PasswordInput
            name="user[password]"
            placeholder="Password"
            register={register}
            validation={ { required: 'Please provide your password', minLength: {value: 8, message: 'Your password must be at least 8 characters' } } }
            initialValue=""
          />
        </FormGroup>
        <FormGroup label="Password" required={true} name="user[password_confirmation]" errorMessage={errors.user && errors.user.password_confirmation}>
          <PasswordInput
            name="user[password_confirmation]"
            placeholder="Confirm your password"
            register={register}
            validation={ {
              required: 'Please confirm your password',
              validate: value => validatePasswordConfirmation(value)
            } }
            initialValue=""
          />
        </FormGroup>
        </div>
        <div className="actions row">
          <input type="submit" value="Change my password" className="btn btn-success col-xs-4 float-end" />
        </div>
      </form>
  )
}

export default PasswordResetForm;
