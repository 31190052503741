import React, { useReducer } from "react";
import PropTypes from "prop-types";
import TrashIcon from "../../../../../../images/airserve-icons/trash-r.svg";

const MultiInput = ({
  initialValue  = [],
  inputComponent: InputComponent,
  blockKey,
  accessor,
  errors,
  register,
  unregisterInput,
}) => {
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      values: initialValue.map((value, index) => ({ index: index, ...value })),
      counter: initialValue.length,
    }
  );

  const { values, counter } = data;

  const addInput = () =>
    setData({
      values: values.concat({ index: counter, [accessor]: "" }),
      counter: counter + 1,
    });

  const removeInput = (index, name) => {
    unregisterInput(name);
    setData({
      values: values.filter((valueItem) => valueItem.index !== index),
    });
  }


  const canRemove = values.length > 1;

  return (
    <>
      {values.map((value) => (
        <div key={value.index}>
          {value.id && (
            <input
              value={value.id}
              readOnly={true}
              hidden={true}
              {...register(`[${blockKey}][${value.index}][id]`)}
            />
          )}
          <InputComponent
            register={register}
            key={value.index}
            name={`[${blockKey}][${value.index}][${accessor}]`}
            inputGroupClassName="mb-2"
            initialValue={value[accessor]}
            postfix={
              canRemove && (
                <RemoveInputButton onClick={() => removeInput(value.index, `[${blockKey}][${value.index}]`)} />
              )
            }
            errorMessage={errors && errors[value.index]}
          />
        </div>
      ))}
      <AddInputButton onClick={addInput} />
    </>
  );
};

const AddInputButton = ({ onClick }) => (
  <div className="btn btn-small btn-primary float-end mt-2" onClick={onClick}>
    Add subdomain
  </div>
);

const RemoveInputButton = ({ onClick }) => (
  <TrashIcon onClick={onClick} />
);

MultiInput.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.any),
  inputComponent: PropTypes.func,
};

export default MultiInput;
