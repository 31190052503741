import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const InputError = ({ message = "", className }) => (
  <div className={`form-error-text ${className}`}>{message}</div>
);

InputError.propTypes = {
  message: PropTypes.string,
};

export default InputError;
