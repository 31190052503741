import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FormGroup = ({
  label = "",
  labelSize = "form-label",
  inputSize,
  name = null,
  initialValue = "",
  errorMessage = {},
  toggleLabel = true,
  children,
}) => {
  const [value, setValue] = useState(false);
  const [displayClass, setDisplayClass] = useState("visibility-none");
  const [errorClass, setErrorClass] = useState("");

  const input = (event) => {
    setValue(event.target.value.length > 0);
    if (event.target.value.length > 0) {
      setDisplayClass("");
    } else {
      setDisplayClass("visibility-none");
    }
  };

  useEffect(() => {
    if (initialValue !== "") {
      setValue(true);
      setDisplayClass("");
    }
  }, [initialValue]);

  useEffect(() => {
    if (Object.keys(errorMessage).length > 0) {
      setErrorClass("error-message");
      setDisplayClass("");
    } else {
      setErrorClass("");
    }
  }, [errorMessage]);

  useEffect(() => {
    if (name && toggleLabel) {
      document.getElementsByName(name)[0].addEventListener("input", input);
    } else {
      setDisplayClass("");
    }
  }, [name]);

  return (
    <div className={`mb-3 ${inputSize} ${errorClass}`}>
      <label className={labelSize + " " + displayClass + " " + errorClass}>
        {errorClass ? errorMessage.message : label}
      </label>
      {children}
    </div>
  );
};

FormGroup.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.any,
  errorMessage: PropTypes.object,
  labelSize: PropTypes.string,
  inputSize: PropTypes.string,
  name: PropTypes.string,
  toggleLabel: PropTypes.bool,
};

export default FormGroup;
