import { createTypes } from 'redux-action-creator';

export const BannerTypes = createTypes([
  'FETCH',
  'PUBLISH',
  'RECEIVE',
  'REFRESH',
  'RECEIVE_BANNER',
  'DELETE_SUCCESS',
  'DELETE_FAILED',
  'ARCHIVE_SUCCESS',
  'RESTORE_SUCCESS',
], 'BannerTypes');
