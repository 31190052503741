import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MultiUploaderService from "@api/multi_uploader";

export const removeFrom = (container, removeId) => {
  return container.filter(item => item?.id !== removeId)
}

export const receiveResponse = (item, state) => {
  return state.data.map(banner => banner?.id === item?.id ? item : banner);
}

export const deleteBanner = createAsyncThunk(
  "deleteBanner",
  async (args, thunkAPI) => {
    try {
      await MultiUploaderService.deleteBanner(args);
      return args.id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBannerById = createAsyncThunk(
  "updateBannerById",
  async (args, thunkAPI) => {
    try {
      const response = await MultiUploaderService.updateBannerById(args.id, args.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMulti = createAsyncThunk(
  "createMulti",
  async (args, thunkAPI) => {
    try {
      const response = await MultiUploaderService.createMulti(args.campaignId, args.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resubmitErrorEntry = createAsyncThunk(
  "resubmitErrorEntry",
  async (args, thunkAPI) => {
    try {
      const response = await MultiUploaderService.createMulti(args.campaignId, args.data);
      return {response: response, index: args.index}
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const initialState = {
  data: [],
  unsaved: [],
  saved: [],
  error: null,
  needsUrlIds: [],
  isLoading: false,
};

export const landingTypes = ['image', 'video']
export const posterTypes = ['video'];

const multiUploaderSlice = createSlice({
  name: "multiUploader",
  initialState,
  reducers: {
    setUrl(state, action){
      const { id, url, bannerType } = action.payload; 
      const index = state.needsUrlIds.findIndex(item => item === id)


      if(landingTypes.includes(bannerType)){
        if (index === -1 && url.length === 0) state.needsUrlIds.push(id)
        if (index !== -1 && url.length > 0) state.needsUrlIds.splice(index, 1)
      }
    },
    removeError(state, { payload: { index } }) {
      state.data.splice(index, 1);
    },
    replaceDataAtIndex(state, { payload: { entry, index } }) { 
      state.data[index] = entry;
    },
    addDataEntry(state, action) {
      state.data.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resubmitErrorEntry.fulfilled, (state, action) => {
        const { index, response } = action.payload;
        if (response.error) {
          state.data.splice(index, 1, response);
        } else {
          // Remove the error and push a new banner entry
          state.data.splice(index, 1);
          state.data.push(response);
          state.unsaved.push(response);
        }
      })
      .addCase(resubmitErrorEntry.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createMulti.fulfilled, (state, action) => {
        state.isLoading = false;
        let item = action.payload
                if (item.error) {
          // Errors go to the top of the list
          state.data.unshift(item);
        }
        if (!item.error) {
          state.data.push(item);
          state.unsaved.push(item);
        }

        // Increment counter for urls
        if(landingTypes.includes(item.type) && !item.url){
          state.needsUrlIds.push(item.id)
        }
      })
      .addCase(createMulti.pending, (state, action) => {
        state.isLoading = true;
      }
      )
      .addCase(createMulti.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.saved = removeFrom(state.saved, action.payload),
        state.unsaved = removeFrom(state.unsaved, action.payload),
        state.data = removeFrom(state.data, action.payload)
        state.needsUrlIds = state.needsUrlIds.filter(item => item !== action.payload)
      })
      .addCase(updateBannerById.fulfilled, (state, action) => {
        state.data = receiveResponse(action.payload, state),
        state.saved = [...state.saved, action.payload],
        state.unsaved = removeFrom(state.unsaved, action.payload.id)
      })

  },
});

export const { removeError, setUrl, addDataEntry } = multiUploaderSlice.actions;
const { reducer } = multiUploaderSlice;
export default reducer;
