import React from "react";
import CommonInput from '../common_input';

const EmailInput = (props) => (
  <CommonInput
    type='email'
    {...props }/>
);

export default EmailInput;
