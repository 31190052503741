import { SuperadminTypes } from "./types";

export function SuperadminStore(
  state = {
    isFetching: false,
    fetchingAccounts: false,
    billingComplete: false,
    accounts: [],
    account: {},
    heartbeatData: [],
    isUpdating: false,
    summarize: {
      total_impressions: 0,
      total: 0,
      total_per_currency: {},
      avg_cpm: 0,
    },
  },
  action
) {
  switch (action.type) {
    case SuperadminTypes.CLEAR_ACCOUNT:
      return {
        ...state,
        account: {},
      };
    case SuperadminTypes.UPDATING_ACCOUNT:
      return {
        ...state,
        isUpdating: true,
      };
    case SuperadminTypes.UPDATING_ACCOUNT_COMPLETE:
      return {
        ...state,
        isUpdating: false,
      };
    case SuperadminTypes.FETCH_ACCOUNTS:
      return { ...state, fetchingAccounts: true };
    case SuperadminTypes.RECEIVE_ACCOUNTS:
      return { ...state, accounts: action.data };
    case SuperadminTypes.FETCHING_ACCOUNT:
      return { ...state, isFetching: true };
    case SuperadminTypes.RECEIVE_ACCOUNT:
      return {
        ...state,
        account: action.data,
        isFetching: false,
      };
    case SuperadminTypes.FETCH_HEARTBEAT_ACCOUNTS:
      return { ...state, isFetching: true };
    case SuperadminTypes.RECEIVE_HEARTBEAT_ACCOUNTS:
      const heartbeatData = state.accounts.map((account) => {
        let find = action.data.find((acc) => acc.account_id === account.id);

        if (find) {
          account = {
            ...account,
            ...find,
          };
        } else {
          account = {
            ...account,
            clicks: 0,
            impressions: 0,
            inscreen: 0,
            pauses: 0,
            plays: 0,
            stops: 0,
          };
        }
        return { ...account, isFetching: false };
      });
      return {
        ...state,
        heartbeatData,
      };
    case SuperadminTypes.FETCH_ACCOUNTS_DATA:
      return {
        ...state,
        summarize: {
          total_impressions: 0,
          total_per_currency: {},
          total: 0,
          avg_cpm: 0,
        },
        isFetching: true,
      };
    case SuperadminTypes.RECEIVE_ACCOUNTS_DATA:
      const accounts = state.accounts.map((acc) => {
        const account = action.data.find((el) => el.id === acc.id);
        if (account) {
          const { account_id, ...account_attrs } = account;
          acc = {
            ...acc,
            ...account_attrs,
          };
        }
        return acc;
      });
      const summarize = {
        total_impressions: 0,
        total_per_currency: {},
        total: 0,
        avg_cpm: 0,
      };
      accounts.forEach((account) => {
        if (account.impressions) {
          summarize.total_impressions += account.impressions;
          if (account.plays) {
            summarize.total_impressions += account.plays;
          }
          if (account.contracts && account.contracts.length) {
            account.contracts.forEach((contract) => {
              summarize.total += contract.total;
              if(!summarize.total_per_currency[contract.currency]){summarize.total_per_currency[contract.currency] = 0;}
              summarize.total_per_currency[contract.currency] += contract.total || 0;
            });
          }
        }
      });
      summarize.avg_cpm =
        summarize.total / (summarize.total_impressions / 1000);
      return {
        ...state,
        summarize,
        isFetching: false,
        fetchingAccounts: false,
        accounts: accounts,
      };
    case SuperadminTypes.BILLING_COMPLETE:
      return {
        ...state,
        billingComplete: true,
      };
    default:
      return state;
  }
}
