import React, { useState } from "react";
import CommonInput from '../common_input';
import EyeIcon from "@images/airserve-icons/show-hide-password.svg";

const PasswordInput = (props) => {
  const [hidden, setHidden] = useState(true)

  const toggleShow = () => {
    setHidden(!hidden)
  }

  return (  
    <>
    <div className="password-input-container position-relative">
      <CommonInput
        type={ hidden ? 'password' : 'text' }
        {...props }/> 
        <div onClick={toggleShow} className={`password-icon position-absolute top-0 end-0 ${ !hidden && 'show' }`}>
          <EyeIcon/>
        </div>
    </div>
    </>
  );
}



export default PasswordInput;
