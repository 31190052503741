import { AccountVendorGroupTypes } from "./types";

export function AccountVendorGroupStore (
  state = {
    isUpdating: false,
    vendorGroups: {},
    status: 'stale',
    showVendorGroupForm: false,
  },
  action
) {
  switch (action.type) {
    case AccountVendorGroupTypes.FETCH_VENDORGROUPS:
      return {
        ...state,
        isUpdating: true,
        status: 'fetching',
      };
    case AccountVendorGroupTypes.FETCH_VENDORGROUPS_COMPLETE:
      return {
        ...state,
        isUpdating: false,
        status: 'done',
        vendorGroups: action.data.group_to_url
      };
    default:
      return state;
  }
}
