import { BannerZoneTypes } from "./types";
import { receiveSingle, addSingle, updateSingle } from "./operations";

export const BannerZoneStore = (
  state = {
    isFetching: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case BannerZoneTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case BannerZoneTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data, 
      };
    case BannerZoneTypes.REQUEST:
      return {
        ...state,
        isFetching: false,
      };
    case BannerZoneTypes.CREATE:
      return {
        ...state,
        isFetching: false,
      };
    case BannerZoneTypes.REFRESH:
      return {
        ...state,
        refresh: true,
      };
    case BannerZoneTypes.SINGLE_ADDED:
      return {
        ...state,
        fetching: false,
        data: addSingle(action, state)
      };
    case BannerZoneTypes.SINGLE_UPDATED:
      return {
        ...state,
        fetching: false,
        data: updateSingle(action, state)
      };
    default:
      return state;
  }
};
