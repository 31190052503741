import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, TextInput, PasswordInput } from "../shared/form";

const AcceptInvitationForm = (props) => {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({criteriaMode: "all"});
  const { invitationToken } = props;

  const onSubmit = data => {
    document.getElementById("acceptInvitationForm").submit();
  }

  const validatePasswordConfirmation = (value) => {
    const password = getValues(["user[password]"])[0];
    return (password === value) || "Passwords do not match";
  }

  return (
    <form id="acceptInvitationForm" action="/users/invitation" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <input type="hidden" name="user[invitation_token]" value={invitationToken} />
      <input type="hidden" name="_method" value="put" />
      <div className="row">
        <FormGroup label="First name" required={true} name="user[first_name]" errorMessage={errors.user && errors.user.first_name}>
          <TextInput
            name="user[first_name]"
            placeholder="First name"
            register={register}
            validation={ { required: 'Please provide your first name' } }
            initialValue=""
          />
        </FormGroup>
        <FormGroup label="Last name" required={true} name="user[last_name]" errorMessage={errors.user && errors.user.last_name}>
          <TextInput
            name="user[last_name]"
            placeholder="Last name"
            register={register}
            validation={ { required: 'Please provide your last name' } }
            initialValue=""
          />
        </FormGroup>
        <FormGroup label="Password" required={true} name="user[password]" errorMessage={errors.user && errors.user.password}>
          <PasswordInput
            name="user[password]"
            placeholder="Password"
            register={register}
            validation={ { required: 'Please provide your password' } }
            initialValue=""
          />
        </FormGroup>
        <FormGroup label="Password" required={true} name="user[password_confirmation]" errorMessage={errors.user && errors.user.password_confirmation}>
          <PasswordInput
            name="user[password_confirmation]"
            placeholder="Confirm your password"
            register={register}
            validation={ {
              required: 'Please confirm your password',
              validate: value => validatePasswordConfirmation(value)
            } }
            initialValue=""
          />
        </FormGroup>
      </div>
      <div className="actions row">
        <input type="submit" value="Register" className="btn btn-success col-xs-4 float-end" />
      </div>
    </form>
  )
}

export default AcceptInvitationForm;
