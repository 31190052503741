import { CompanyEventTypes } from "./types";

export const CompanyEventsStore = (
  state = {
    isFetching: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case CompanyEventTypes.FETCH:
      return {
        ...state,
        isFetching: action.data.loading,
      };
    case CompanyEventTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    default:
      return state;
  }
};
