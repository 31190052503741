import React from "react";
import PropTypes from "prop-types";
import InputGroup from "../input_group";
import { invalidField } from "../styles";

// Implemented but currently unused as it wasn't needed

const NumberInput = ({
  initialValue,
  name = "input_name",
  placeHolder,
  register = () => {},
  errorMessage = null,
  validation,
  postfix = null,
  className,
}) => (
  <InputGroup postfix={postfix} className={className}>
    <input
      type="number"
      placeholder={placeHolder}
      defaultValue={initialValue}
      className="form-control"
      style={errorMessage && invalidField}
      {...register(name, validation)}
    />
  </InputGroup>
);

NumberInput.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.number,
  errorMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  postfix: PropTypes.node,
};

export default NumberInput;
