import { createTypes } from 'redux-action-creator';

export const CompanyTypes = createTypes([
  'NEW',
  'CREATED',
  'FETCH',
  'RECEIVE',
  'RECEIVE_COMPANY',
  'UPDATE_BRANDS',
  'UPDATED',
], 'CompanyTypes');
