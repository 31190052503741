import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkResponse, postHeaders } from "@utils";

const initialState = {
  isPublishing: false,
  errors: false,
  hasPublished: false,
}

export const publishBanners = createAsyncThunk(
  "publishBanners",
  async (args, thunkAPI) => {
    try {
      const response = await fetch(`/api/v2/banners/bulk_publisher`, {
        method: "POST",
        headers: postHeaders(),
        body: JSON.stringify({banner_ids: args.banner_ids}),
      })
        .then(checkResponse)

      return response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue(`${error.status} - ${error.statusText}`);
    }
  }
);

const publishBannerSlice = createSlice({
  name: 'bannerPublishing',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(publishBanners.pending, (state, action) => {
        state.hasPublished = false;
        state.isPublishing = true;
      })
      .addCase(publishBanners.fulfilled, (state, action) => {
        state.isPublishing = false;
        state.successfulPublish = true;
      })
      .addCase(publishBanners.rejected, (state, action) => {
        state.errors = true;
      })
  }
});

const { reducer } = publishBannerSlice;;
export default reducer;
