import { createTypes } from 'redux-action-creator';

export const BannerZoneTypes = createTypes([
  'FETCH',
  'RECEIVE',
  'REQUEST',
  'CREATE',
  'RECEIVE_SINGLE',
  'SINGLE_ADDED',
  'SINGLE_UPDATED',
], 'BannerZoneTypes');
