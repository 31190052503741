import { AccountImpressionTypes } from "./types";

export const AccountImpressionsStore = (
  state = {
    isFetching: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case AccountImpressionTypes.FETCH:
      return {
        ...state,
        isFetching: true
      };
    case AccountImpressionTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    default:
      return state;
  }
}
