import React from "react";
import PropTypes from "prop-types";

const Postfix = ({ children = <></> }) =>
  children && <span className="input-group-addon">{children}</span>;

Postfix.propTypes = {
  children: PropTypes.node,
};

export default Postfix;
