import { BannerTypes } from "./types";
import { receiveBanner } from "./operations"

export const BannerStore = (
  state = {
    isFetching: false,
    isPublishing: false,
    bannerId: null,
    data: [],
    deletedId: null,
    archivedId: null,
    deletionFailed: false,
    refresh: false,
    banners: {},
  },
  action
) => {
  switch (action.type) {
    case BannerTypes.FETCH:
      return {
        ...state,
        isFetching: true,
        isPublishing: false,
        refresh: false,
      };
    case BannerTypes.PUBLISH:
      return {
        ...state,
        isFetching: false,
        isPublishing: true,
        bannerId: action.id,
        banners: {
          ...state.banners,
          [action.id]: {
            isFetching: false,
            isPublishing: true,
          }
        },
        refresh: false,
      };
    case BannerTypes.DELETE_SUCCESS:
      return {
        ...state,
        deletedId: action.data.id,
        data: state.data.filter((banner) => banner.id != action.data.id),
        hasRestored: false,
        refresh: true,
      };
    case BannerTypes.DELETE_FAILED:
      return {
        ...state,
        deletionFailed: true,
      }
    case BannerTypes.ARCHIVE_SUCCESS:
      return {
        ...state,
        archivedId: action.data.id,
        hasRestored: false,
        refresh: true,
      };
    case BannerTypes.RESTORE_SUCCESS:
      return {
        ...state,
        hasRestored: true,
        deletedId: null,
        refresh: false,
      };
    case BannerTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        isPublishing: false,
        data: action.data,
        refresh: false,
      };
    case BannerTypes.RECEIVE_BANNER:
      return {
        ...state,
        isFetching: false,
        isPublishing: false,
        banners: {
          ...state.banners,
          [action.data.id]: {
            isFetching: false,
            isPublishing: false,
            published: action.data.published === true,
          }
        },
        refresh: false,
        data: receiveBanner(action, state)
      };
    default:
      return state;
  }
};
