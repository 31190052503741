import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";

const initialState = {
    isFetching: false,
    data: '',
    error: '',
    brandIds: [],
    companyIds: [],
    selectAll: false,
    id_type: '',
    jsTreeData: ''
}

export const transformDataToJsTree = (state, initialCompanyIds, initialBrandIds) => {
    let jsTreeData = []

    let sortedCompanies = [...state.data].sort((a,b) => a.name.localeCompare(b.name))

    for (let company of sortedCompanies){
        let companyNode = {
            ids: company.brands.flatMap(b => b.id),
            text: company.name,
            state: {
                id: company.id, 
                type: 'company',
                selected: initialCompanyIds && initialCompanyIds.includes(company.id),
                expanded: false
            },
            text: company.name,
            nodes: []
        }

        let sortedBrands = [...company.brands].sort((a,b) => a.name.localeCompare(b.name))

        for (let brand of sortedBrands){
            let brandNode = {
                ids: [brand.id],
                key: `${company.id}-${brand.id}`,
                text: brand.name,
                state: {
                    c_id: company.id,
                    id: brand.id, 
                    type: 'brand',
                    expanded: false,
                    selected: false,
                    text: brand.name
                },
            }

            companyNode.nodes.push(brandNode)
        }

        jsTreeData.push(companyNode)
    }

    state.jsTreeData = jsTreeData
}


export const fetchAllCompaniesAndBrands = createAsyncThunk('fetchAllCompaniesAndBrands', async (args, thunkAPI) => {
    const url = '/api/v2/analytics/companies/'

    try {
        const res = await fetch(url)
        const data = await res.json()
        return data
    } catch (err){
        return thunkAPI.rejectWithValue(err)
    }
})

const analyticsSlice = createSlice({
    name: 'analyticsSlice',
    initialState,
    reducers: {
        initializeJsTreeData(state, action) {
            transformDataToJsTree(state)
        },
        setSelectAll(state, action){
            state.selectAll = action.payload
            state.id_type = state.selectAll ? 'account' : 'brand'

            if(state.data.length === 0) return
            
            if(state.selectAll){
                state.brandIds = state.data.flatMap(d => d.brands.map(b => b.id));
                state.companyIds = state?.data?.map(d => d.id)
            }

            if(!state.selectAll){
                state.brandIds = []
                state.companyIds = []
            }
        },
        onTreeChange(state,action){
            const {brand_ids, company_ids, type, remove} = action.payload

            if(remove){
                state.brandIds = state.brandIds.filter(id => !brand_ids.includes(id))
                state.companyIds = state.companyIds.filter(id => !company_ids.includes(id))
            } else {
                state.brandIds = [...new Set([...state.brandIds, ...brand_ids])]
                state.companyIds = [...new Set([...state.companyIds, ...company_ids])]
            }
            state.id_type = type
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllCompaniesAndBrands.fulfilled, (state, action) => {
                state.isFetching = false
                state.data = action.payload
                transformDataToJsTree(state, action.meta.arg.initialCompanyIds, action.meta.arg.initialBrandIds)
            })
            .addCase(fetchAllCompaniesAndBrands.pending, (state) => {
                state.isFetching = true
            })
            .addCase(fetchAllCompaniesAndBrands.rejected, (state) => {
                state.isFetching = false
                state.error = 'Error fetching companies and brands'
            })
    }
})


export const {setSelectAll, initializeJsTreeData, onTreeChange } = analyticsSlice.actions
const {reducer} = analyticsSlice
export default reducer