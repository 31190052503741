import { BannerScheduleTypes } from "./types";

export const BannerScheduleStore = (
  state = {
    isFetching: false,
    data: [],
    recurring: []
  },
  action
) => {
  switch (action.type) {
    case BannerScheduleTypes.FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case BannerScheduleTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case BannerScheduleTypes.REQUEST:
      return {
        ...state,
        isFetching: false,
      };
    case BannerScheduleTypes.RECEIVE_RECURRING:
      return {
        ...state,
        isFetching: false,
        recurring: action.data,
      };
    default:
      return state;
  }
};