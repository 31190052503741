import { BrandTypes } from "./types";

export const BrandStore = (
  state = {
    isFetching: false,
    data: [],
    deletedId: null,
    deletionFailed: false,
  },
  action
) => {
  switch (action.type) {
    case BrandTypes.FETCH:
      return {
        ...state,
        isFetching: action.data.loading,
      };
    case BrandTypes.RECEIVE:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case BrandTypes.DELETE_SUCCESS:
      return {
        ...state,
        deletionFailed: false,
        deletedId: action.data.id,
        hasRestored: false,
      };
    case BrandTypes.DELETE_FAILED:
      return {
        ...state,
        deletionFailed: true,
      }
    case BrandTypes.RESTORE_SUCCESS:
      return {
        ...state,
        hasRestored: true,
        deletedId: null,
      };
    default:
      return state;
  }
};
