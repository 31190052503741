import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputGroup from "../input_group";
import { invalidField } from "../styles";

const CommonInput = ({
  initialValue = "",
  name = "input_name",
  register = () => {},
  formatValue = (previousValue, newValue) => newValue,
  validation = {},
  postfix = null,
  inputGroupClassName,
  type = 'text',
  placeholder = "",
  onChange = () => {},
}) => {
  const [value, setValue] = useState(initialValue);

  const inputRegistration = register(name, validation);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    setValue(formatValue(value, event.target.value));
    inputRegistration.onChange(event);
    onChange(event);
  };

  return (
    <InputGroup className={inputGroupClassName} postfix={postfix}>
      <input
        type={type}
        placeholder={placeholder}
        className="form-control"
        {...inputRegistration}
        value={value}
        onChange={(event) => handleChange(event)}
        aria-label={placeholder}
      />
    </InputGroup>
  );
};

CommonInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  formatValue: PropTypes.func,
  validation: PropTypes.object,
  postfix: PropTypes.node,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default CommonInput;
