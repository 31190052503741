import { produce } from "immer";
import { MarketplaceCampaignTypes } from "./types";
import { endOfDay, differenceInDays } from 'date-fns';

export const MarketplaceCampaignStore = (
  state = {
    banners: [],
    lastBannerId: null,
    processing: false,
    startDate: null,
    endDate: null,
    loaded: false,
  },
  action
) => {
  switch(action.type) {
    case MarketplaceCampaignTypes.ADDING_BANNER:
      return {
        ...state,
        processing: true
      }
    case MarketplaceCampaignTypes.CREATE:
      return {
        ...state,
        processing: true
      }
    case MarketplaceCampaignTypes.DELETE_BANNER:
      return {
        ...state,
        banners: [
          ...state.banners.filter(banner => banner.id !== action.data.id)
        ],
        lastBannerId: null,
      }
    case MarketplaceCampaignTypes.FETCHING:
      return {
        ...state,
        processing: true,
      }
    case MarketplaceCampaignTypes.RECEIVE_NEW_CAMPAIGN:
      return {
        ...state,
        banners: action.data.banners,
        lastBannerId: Math.max(action.data.banners.map(b => b.id)),
        processing: false,
        campaign_name: action.data.campaign_name
      }
    case MarketplaceCampaignTypes.RECEIVE_EXISTING_CAMPAIGN:
      return {
        ...state,
        banners: action.data.banners,
        processing: false,
        loaded: true,
        campaign_name: action.data.campaign_name
      }
    case MarketplaceCampaignTypes.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        // banners: action.data.banners,
        campaigns: action.data,
        lastBannerId: null,
        processing: false,
        // campaign_name: action.data.campaign_name
      }
    case MarketplaceCampaignTypes.RECEIVE_BANNER:
      return {
        ...state,
        banners: [...state.banners, action.data.banner],
        lastBannerId: action.data.banner.id,
        processing: false,
      }
    case MarketplaceCampaignTypes.SET_ORDER_DATE_RANGE:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: endOfDay(action.data.endDate),
        durationInDays: differenceInDays(new Date(action.data.endDate), new Date(action.data.startDate)) + 1,
      }
    case MarketplaceCampaignTypes.SET_SECTIONS:
      return {
        ...state,
        sections: action.data.sections,
        budget: action.data.budget
      }
    case MarketplaceCampaignTypes.UPDATE_BANNER:
      const index = state.banners.findLastIndex((banner) => banner.id === action.data.id);
      return {
        ...state,
        banners: [
          ...state.banners.slice(0, index),
          action.data,
          ...state.banners.slice(index + 1)
        ],
        lastBannerId: null,
      }
    case MarketplaceCampaignTypes.UPDATE_SECTION:
      return {
        ...state,
        sections: produce(state.sections, draft => {
          const section_index = draft.findLastIndex((section) => {
            return section.id === action.data.id;
          })
          if (section_index !== -1) draft[section_index] = action.data;
        })
      }
    case MarketplaceCampaignTypes.DEBUG:
      return {
        ...state,
        sections: action.data.sections,
        campaign_name: action.data.campaign.name,
        campaign_id: action.data.campaign.id,
        startDate: action.data.startDate,
        endDate: endOfDay(action.data.endDate),
        banners: [{id: 166286, name: "dummy value 1"}],
        budget: action.data.budget
      }
    default:
      return state;
  }
}
