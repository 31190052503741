import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = ({
  name,
  value,
  disabled,
  title = "",
  initialChecked,
  onChange = () => {},
  onCheckChange = (e) => {},
  register = () => {},
}) => {
  const disabledValue = disabled ? "disabled" : "";
  const [checked, setChecked] = useState(initialChecked);
  const checkboxWrapperClasses = classNames("", {
    "checkbox-wrapper-disabled": disabled,
  });
  const checkboxClasses = classNames("", {
    "checkbox-disabled": disabled,
    "checkbox-checked": checked,
  });

  const toggle = (e) => {
    let newVal = !checked;
    setChecked(newVal);
    onChange(e);
    onCheckChange(newVal);
  };

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <label className={`checkbox-wrapper ${checkboxWrapperClasses}`}>
      <span className={`checkbox ${checkboxClasses}`}>
        <input
          type="checkbox"
          defaultChecked={checked}
          value={value}
          onClick={toggle}
          disabled={disabledValue}
          className="checkbox-input"
          {...register(name)}
        />
        <span className="checkbox-inner position-absolute" />
      </span>
      <span>{title}</span>
    </label>
  );
};

Checkbox.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  initialChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default Checkbox;
