import React from "react";
import PropTypes from "prop-types";
import Postfix from "../postfix";
import { isEmpty } from "@utils";

const InputGroup = ({ className = "", children, postfix  = null}) => (
  <div className={`${className} ${isEmpty(postfix) ? "" : `input-group`}`}>
    {children}
    <Postfix>{postfix}</Postfix>
  </div>
);

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  postfix: PropTypes.node,
};

export default InputGroup;
